<template>
  <div class="xm_add" v-loading="loading">
    <el-form ref="form" label-width="80px">
      <el-form-item label="班级名称">
        <el-select clearable v-model="className" placeholder="请选择班级">
          <el-option
            v-for="item in classes"
            :label="item"
            :value="item"
            :key="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="项目信息">
        <el-cascader
          v-model="value"
          :options="options"
          :props="{ expandTrigger: 'hover' }"
          @change="handleChange"
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即创建</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "XiangmuAdd",
  data() {
    return {
      value: [],
      options: [],
      className: "",
      classes: [],
      jieduan: [],
      xms: [],
      loading: false,
    };
  },
  async created() {
    let res = await this.$datas.getXM;
    let res1 = await this.$datas.jindu;
    //获取阶段
    let data1 = [];
    for (let i in res.data) {
      let item = res.data[i];
      let exist = -1;
      for (let j in data1) {
        if (data1[j].label == item.jieduan_name) {
          exist = j;
          break;
        }
      }
      if (exist != -1) {
        //存在，此时直接存储数据
        data1[exist].children.push({
          label: item.xiangmu_name,
          value: item.xiangmu_id,
        });
      } else {
        //不存在，此时创建新数据存储
        data1.push({
          value: item.jieduan_id,
          label: item.jieduan_name,
          children: [
            {
              label: item.xiangmu_name,
              value: item.xiangmu_id,
            },
          ],
        });
      }
    }
    this.options = data1;
    // 处理班级
    let data2 = res1.data.msg.data;
    for (let key in data2) {
      this.classes.push(key);
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      //组合数据
      if (this.className && this.value.length > 0) {
        //完成数据提交
        this.$datas.meta = { className: this.className };
        let res = await this.$datas.addClassName;
        //存储项目检查信息
        this.$datas.meta = {
          jieduan_id: this.value[0],
          xiangmu_id: this.value[1],
          className: this.className,
        };
        res = await this.$datas.addXMCheck;
        if (res.status == 200) {
          for (let i in res.data.xm.xms) {
            this.$datas.meta = {
              xm_id: res.data.id,
              gongneng_name: res.data.xm.xms[i].gongneng_name,
              gongneng_id: res.data.xm.xms[i].gongneng_id,
              quanzhong:res.data.xm.xms[i].quanzhong
            };
            await this.$datas.addBJCheck;
          }
          //提示用户
          this.$message("检查记录已添加");
          this.$router.replace({name:"XM"});
        }
      } else {
        //提示用户
        this.$message("请选择班级和项目信息");
      }
      this.loading = false;
    },
    handleChange(value) {
      this.value = value;
    },
  },
};
</script>
